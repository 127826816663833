<style lang="scss" scoped>
.welfare.template {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #231d80;
  z-index: 2;
  .layout {
    width: 100vw;
    height: calc(100vw * 0.5625);
    max-width: 19.2rem;
    max-height: 10.8rem;
    overflow: hidden;
    position: relative;
    .pos {
      position: absolute;
    }
    .bg {
      width: 104.2%;
      height: 104.2%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .title {
      width: 100%;
      text-align: center;
      top: 9%;
      img {
        height: auto;
        width: 44%;
        &.en {
          width: 80%;
        }
      }
    }
    .texture_01 {
      width: 49.375%;
      height: auto;
      top: 53.42%;
      left: 50%;
      transform: translateX(-50%);
    }
    .texture_02 {
      width: 32.55%;
      height: auto;
      top: 38.55%;
      left: 50%;
      transform: translateX(-50%);
    }
    .box {
      width: 37.66%;
      height: auto;
      top: 48.7%;
      left: 35.21%;
    }
    .coin {
      width: 23.07%;
      height: auto;
      top: 18.52%;
      left: 39.95%;
    }
    .particle {
      width: 16.56%;
      height: auto;
      top: 26.57%;
      left: 42.81%;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .form {
      width: 20.83%;
      height: 20.37%;
      top: 75.46%;
      left: 39.58%;
      display: flex;
      flex-direction: column;
      .input {
        width: 100%;
        height: 30%;
        background-color: #0f0f1e99;
        transform: skewX(-26deg);
        margin-bottom: 5%;
        text-align: center;
        position: relative;
        &.readonly{
          background-color: #33333350;
          cursor: default;
        }
        .getcode {
          position: absolute;
          top: 0;
          right: -0.3rem;
          transform: translateX(100%);
          width: 2rem;
          height: 100%;
          background-color: #0f0f1e99;
          font-size: 0.32rem;
          color: #00e7ff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.24rem;
          cursor: pointer;
          &.opacity{
            opacity: .5;
            cursor: default
          }
        }
        input {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 0;
          outline: 0;
          background-color: transparent;
          font-size: 0.32rem;
          color: #fff;
          padding: 0 0.2rem;
          text-align: center;
          transform: skewX(26deg);
          &::-webkit-input-placeholder,
          &::placeholder {
            color: #e0e0e0;
          }
        }
      }
      .btn {
        width: 100%;
        height: 30%;
        text-align: center;
        font-size: 0.3rem;
        font-weight: bold;
        color: #744c1d;
        display: flex;
        justify-content: center;
        span {
          cursor: pointer;
          width: 60%;
          height: 100%;
          background: linear-gradient(180deg, #ffefc6 0%, #f5bf51 100%);
          border: 2px solid rgba(0, 0, 0, 0.5);
          display: block;
          transform: skewX(-26deg);
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: linear-gradient(180deg, #f5bf51 0%, #ffefc6 100%);
          }
        }
      }
    }
    .tips {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #ffffff00;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 100%;
        height: 6%;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 0.32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: italic;
      }
    }
  }
}
</style>
<template>
  <div class="welfare v2 template">
    <div class="layout" ref="layoutdom" @mousemove="mousemove">
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/bg.png"
        alt=""
        class="bg pos"
        :style="{
          marginLeft: followMouse(40, 20, true)[0] + 'px',
          marginTop: followMouse(40, 20, true)[1] + 'px',
        }"
      />
      <div class="title pos">
        <img
          :class="lang"
          v-if="lang == 'en'"
          src="https://cdn.blockwar.io/web_assets/img/welfare/title_en.png"
          :style="{
            marginLeft: followMouse(20, 10, true)[0] + 'px',
            marginTop: followMouse(20, 10, true)[1] + 'px',
          }"
        />
        <img
          :class="lang"
          v-else
          src="https://cdn.blockwar.io/web_assets/img/welfare/title_cn_f.png"
          :style="{
            marginLeft: followMouse(20, 10, true)[0] + 'px',
            marginTop: followMouse(20, 10, true)[1] + 'px',
          }"
        />
      </div>
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/texture_01.png"
        alt=""
        class="pos texture_01"
        :style="{
          marginLeft: followMouse(5, 2.5)[0] + 'px',
          marginTop: followMouse(5, 2.5)[1] + 'px',
        }"
      />
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/box.png"
        alt=""
        class="box pos"
        :style="{
          marginLeft: followMouse(5, 10)[0] + 'px',
          marginTop: followMouse(5, 10)[1] + 'px',
        }"
      />
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/texture_02.png"
        alt=""
        class="pos texture_02"
        :style="{
          marginLeft: followMouse(15, 7.5)[0] + 'px',
          marginTop: followMouse(15, 7.5)[1] + 'px',
        }"
      />
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/coin.png"
        alt=""
        class="pos coin"
        :style="{
          marginLeft: followMouse(20, 10)[0] + 'px',
          marginTop: followMouse(20, 10)[1] + 'px',
        }"
      />
      <img
        src="https://cdn.blockwar.io/web_assets/img/welfare/particle.png"
        alt=""
        class="pos particle"
        :style="{
          marginLeft: followMouse(25, 12.5)[0] + 'px',
          marginTop: followMouse(25, 12.5)[1] + 'px',
        }"
      />
      <div class="form pos" v-if="userinfo && userinfo.phone_num">
        <div :class="{ input: true, en: lang == 'en',readonly: true }">
          <input
            :style="{ 'font-size': fonsize + 'px' }"
            type="text"
            v-model="formData.phone"
            readonly
            :placeholder="lang == 'en' ? 'Please enter phone number' : '請輸入手機號'"
          />
          <span :data-CD_Num="CD_Num" :class="{getcode:true, opacity: CD_Num==0?false:true}" @click="getcode">{{ btnTxt }}</span>
        </div>
        <div :class="{ input: true, en: lang == 'en' }" v-if="showCodeInput">
          <input
            :style="{ 'font-size': fonsize + 'px' }"
            type="text"
            v-model="formData.code"
            :placeholder="
              lang == 'en' ? 'Please enter the redemption code' : '請輸入驗證碼'
            "
          />
        </div>
        <div class="btn" :style="{ 'font-size': fonsize + 'px' }">
          <span @click="submit(1)">{{ lang == "en" ? "Exchange" : "確認兌換" }}</span>
        </div>
      </div>
      <div class="form pos" v-else-if="userinfo">
        <div :class="{ input: true, en: lang == 'en' }">
          <input
            :style="{ 'font-size': fonsize + 'px' }"
            type="text"
            v-model="formData.phone"
            :placeholder="lang == 'en' ? 'Please enter phone number' : '請輸入手機號'"
          />
        </div>
        <div :class="{ input: true, en: lang == 'en' }">
          <input
            :style="{ 'font-size': fonsize + 'px' }"
            type="text"
            v-model="formData.code"
            :placeholder="
              lang == 'en' ? 'Please enter the redemption code' : '請輸入驗證碼'
            "
          />
        </div>
        <div class="btn" :style="{ 'font-size': fonsize + 'px' }">
          <span @click="submit(0)">{{ lang == "en" ? "Exchange" : "確認兌換" }}</span>
        </div>
      </div>
      <div class="tips" v-show="tipsData.tag">
        <span>{{ tipsData.txt }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, nextTick, onMounted, watch } from "vue";
import common from "../../utils/common";
import axios from "axios";

let formData = ref({
  phone: "",
  code: "",
});

let router = useRouter();
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});
let userinfo = computed(() => {
  if(store.state.userInfo){
    store.commit("userHubStore/set_showUserHub", false);
    if(store.state.userInfo.phone_num){
      formData.value.phone = store.state.userInfo.phone_num;
    }
  }
  else{
    store.commit("userHubStore/set_showUserHub", true);
  }
  console.log('store.state.userInfo',store.state.userInfo)
  return store.state.userInfo;
});

let CD_Num = ref(0);
let VRC = ref();
let getVRC_SI;
const CD60 = () => {
  CD_Num.value = 60;
  getVRC_SI = setInterval(() => {
    if (CD_Num.value >= 1) {
      CD_Num.value = CD_Num.value - 1;
    } else {
      CD_Num.value = 0;
      clearInterval(getVRC_SI);
    }
  }, 1000);
};
let btnTxt = computed(()=>{
  let t;
  if(CD_Num.value==0){
    t = lang.value == "en" ? "Get Code" : "獲取驗證碼";
  }
  else{
    t=CD_Num.value+' S';
  }
  return t;
});

let layoutdom = ref();
let fonsize = ref(lang == "en" ? 28 : 32);

let layoutCenterX = ref(0),
  layoutCenterY = ref(0);
let mousePosX = ref(0),
  mousePosY = ref(0);
let disX = ref(0),
  disY = ref(0);
const mousemove = (event) => {
  window.requestAnimationFrame(() => {
    mousePosX.value = event.pageX;
    mousePosY.value = event.pageY;
    disX.value = mousePosX.value - layoutCenterX.value;
    disY.value = mousePosY.value - layoutCenterY.value;
  });
};

let layoutdomWidth = ref();
let layoutdomHeight = ref();
const followMouse = (x, y, t) => {
  const XR = layoutdomWidth.value / 1920;
  const YR = layoutdomHeight.value / 1080;
  let DIS_x = disX.value * XR * (x / layoutdomWidth.value);
  let DIS_y = disY.value * YR * (y / layoutdomHeight.value);
  if (t) {
    return [-DIS_x, -DIS_y];
  } else {
    return [DIS_x, DIS_y];
  }
};

let tipsData = ref({
  tag: false,
  txt: "",
});

// 獲取驗證碼
const getcode = () => {
  if (formData.value.phone && CD_Num.value==0) {
    showCodeInput.value = true;
    const sendUrl =
      common.userApi.requestUrl + "/user_api/phones/" + formData.value.phone + "/sms";
    const sendData = common.buildSendData({
      type: 6,
    });
    common.showLoading("getcode");
    axios
      .post(sendUrl, sendData)
      .then((r) => {
        if (r.data.code == 0) {
          toastr["success"](r.data.msg);
          CD60();
          if (!common.isProduction) {
            VRC.value = r.data.debug_verify_code;
          }
        } else {
          console.error(r.data.msg);
          toastr["error"](r.data.msg);
        }
      })
      .finally(() => {
        common.hideLoading("getVRC");
      });
  } else if(userinfo.value && !userinfo.value.phone_num) {
    console.log(2);
    let msg = lang == "en" ? "請先在‘個人中心’綁定手機" : "請先在‘個人中心’綁定手機";
    showMsg(msg, () => {
      router.push("/center");
    });
  }
};

let showCodeInput = ref(false);

let showMsgSI;
const showMsg = (msg, cbFn = function () {}) => {
  tipsData.value.tag = true;
  tipsData.value.txt = msg;
  clearTimeout(showMsgSI);
  showMsgSI = setTimeout(() => {
    tipsData.value.tag = false;
    tipsData.value.txt = "";
    cbFn();
  }, 3000);
};
const submit = (t=0) => {
  const sendUrl = common.userApi.requestUrl + "/finance_api/used_welfare_cards";
  const sendData = common.buildSendData({
    phone_number: formData.value.phone,
    op_type: t,
    code: formData.value.code,
  });
  const sendHeader = common.buildHeaders("form");
  common.showLoading("welfare submit");
  axios
    .put(sendUrl, sendData, { headers: sendHeader })
    .then((r) => {
      if (r.data.code == 0) {
        let msg = lang == "en" ? "Receive successfully" : "領取福利成功";
        showMsg(msg, function () {
          router.push("/");
        });
      } else {
        toastr["error"](r.data.msg);
      }
    })
    .finally(() => {
      common.hideLoading("welfare submit");
    });
};
onMounted(() => {
  layoutdomWidth.value = layoutdom.value.clientWidth;
  layoutdomHeight.value = layoutdom.value.clientHeight;
  console.log("layoutdom.value", layoutdom.value);

    const getFontSize = () => {
    layoutdomWidth.value = layoutdom.value.clientWidth;
    layoutdomHeight.value = layoutdom.value.clientHeight;

    layoutCenterX.value = layoutdom.value.clientWidth / 2 + document.body.offsetLeft;
    layoutCenterY.value = layoutdom.value.clientHeight / 2 + document.body.offsetTop;

    console.log(
      "lang",
      lang.value,
      "layoutdomWidth.value:",
      layoutdomWidth.value,
      "layoutdomHeight.value:",
      layoutdomHeight.value
    );
    let layoutWidth = layoutdom.value.clientWidth;
    let fonSize =
      lang.value == "en" ? (layoutWidth / 1920) * 28 : (layoutWidth / 1920) * 32;
    if (lang.value == "en") {
      fonSize > 28 ? (fonSize = 28) : 1;
    } else {
      fonSize > 32 ? (fonSize = 32) : 1;
    }
    fonsize.value = fonSize;
  };
  getFontSize();
  window.addEventListener("resize", () => {
    getFontSize();
  });
});
</script>
